@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-xl: 20px;

  /* Colors */
  --color-gray-100: rgba(0, 0, 0, 0.8);

  /* border radiuses */
  --br-29xl: 48px;
}
/*  */